import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 45vh;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 20px;
  background-color: black;
  color: white;
  font-weight: normal;
  > div {
    max-width: 1920px;
    width: 80%;
    margin: 0 auto;
    border-left: 7px solid rgb(215, 25, 32);
    padding-left: 20px;
    height: 8em;
    span,
    h1 {
      margin: 0;
    }
    span {
      background-color: white;
      color: black;
      padding: 2px 5px;
    }

    h1 {
      font-weight: normal;
      font-size: 3em;
    }
    h2 {
      font-weight: normal;
      font-size: 3em;
      margin: 5px 0;
    }

    h3 {
      font-size: 3em;
      font-weight: bold;
      line-height: 20px;
      color: white;
      margin: 5px 0;
    }

    p {
      margin-top: 50px;
      line-height: 20px;
      color: white;
    }
    @media (max-width: 1023px) {
      h2 {
        font-size: 32px;
      }
      h3 {
        font-size: 27px;
        line-height: 27px;
      }
    }
    @media (max-width: 767px) {
      h1 {
        font-size: 1.8em;
      }
      h3 {
        font-size: 1.4em;
      }
    }
  }
  @media (max-width: 1349px) {
    min-height: 333px;
    div {
      width: 90%;
    }
  }
`;

const PageInfo = ({ header, mainTitle, title, subtitle, paragraph, box }) => (
  <Wrapper>
    <div>
      <span>{header}</span>
      <h1>{mainTitle}</h1>
      <h2>{title}</h2>
      <h3>{subtitle}</h3>
      <p>{paragraph}</p>
      <div className="qual-sign-box">{box}</div>
    </div>
  </Wrapper>
);

export default PageInfo;
