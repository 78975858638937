import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const pageData = {
  header: "Nowoczesna edukacja",
  mainTitle: "Rozwiązania informatyczne",
  subtitle: "i audio-wizualne",
};

const OfferBusinessPage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`edu-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="edu-page">
          <div className="buttons">
            <Link to="#audio-video">
              {" "}
              <button className="btn-red">prac. informatyczna</button>
            </Link>
            <Link to="#infrastructure">
              <button className="btn-red">prac. przedmiotowe</button>
            </Link>
            <Link to="#remote">
              <button className="btn-red">zdalne nauczanie</button>
            </Link>
            <Link to="#officeStaff">
              <button className="btn-red">administracja</button>
            </Link>
            <Link to="#services">
              <button className="btn-red">sprzęt multimedialny</button>
            </Link>
          </div>

          <div className="offer-element">
            <div id="audio-video" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Rozwój umiejętności</h4>
                  <h2>
                    Pracownia <br /> <b>informatyczna</b>
                  </h2>
                </div>
                <p className="headerTxt">
                  Uczniowie mogą <strong>rozwijać swoje umiejętności</strong> w
                  dziedzinie informatyki i technologii w sposób praktyczny i
                  interaktywny
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/technologie.svg")
                          .default
                      }
                    />
                    <h3>najnowsze</h3>
                    <p>technologie</p>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/pracy i sieci.svg")
                          .default
                      }
                    />

                    <h3>bezpieczeństwo</h3>
                    <p>pracy i sieci</p>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/nauka.svg").default
                      }
                    />

                    <h3>interaktywna</h3>
                    <p>nauka</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table list">
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    tablety i notebooki, <br /> szafy do przechowywania
                  </h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header list-wrapper">
                  <ul>
                    <h2>
                      {" "}
                      <b>druk 3D</b>
                    </h2>

                    <li>⇨ drukarki 3D</li>
                    <li>⇨ filamenty</li>
                    <li>⇨ długopisy 3D</li>
                    <li>⇨ skanery 3D</li>
                  </ul>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>komputery stacjonarne - AIO, akcesoria</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>programowanie i roboty</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>podłogi interaktywne</h2>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/eduOffer/edu1.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="infrastructure" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Nowoczesne pracownie</h4>
                  <h2>
                    Pracownie przedmiotowe <br /> <b>i językowe</b>
                  </h2>
                </div>
                <p className="headerTxt">
                  Zmodernizowane sale do nauki to{" "}
                  <strong>przyszłość edukacji!</strong>
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/atrakcyjnosc.svg")
                          .default
                      }
                    />
                    <h3>zwiększenie</h3>
                    <p>atrakcyjności</p>
                    <h3>zajęć</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/horyzonty.svg")
                          .default
                      }
                    />

                    <h3>poszerzanie</h3>
                    <p>horyzontów</p>
                    <h3>uczniów</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/dostepnosc.svg")
                          .default
                      }
                    />

                    <h3>Zapewnienie</h3>
                    <p>dostępności</p>
                    <h3>materiałów</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="table list">
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    okulary VR, <br /> wirtualna rzeczywistość
                  </h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>wizualizery</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>programy multimedialne</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>listoratoria przyrodnicze</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>pomoce dydaktyczne</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>mikroskopy</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    sprzęt dla dzieci, <br /> ze specjalnymi <br /> potrzebami
                    edukacyjnymmi
                  </h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    oprogramowani do pracy <br /> z dziećmi ze specjalnymi{" "}
                    <br /> potrzebami edukacyjnymi
                  </h2>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/eduOffer/edu2.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="remote" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Bez ograniczeń</h4>
                  <h2>
                    {" "}
                    Zdalne nauczanie, <br /> <b>wideokonferencja</b>
                  </h2>
                </div>
                <p className="headerTxt">
                  Nauczanie zdalne <strong>z każdego miejsca</strong> dzięki
                  nowoczesnemu sprzętowi oraz bezpiecznej sieci
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/stabilnosc.svg")
                          .default
                      }
                    />
                    <h3>zapewnienie</h3>
                    <p>stabilności</p>
                    <h3>sieci</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/elastycznosc.svg")
                          .default
                      }
                    />

                    <h3>poprawa</h3>
                    <p>elastyczności</p>
                    <h3>komunikacji</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/komfort2.svg")
                          .default
                      }
                    />
                    <h3>wzrost</h3>
                    <p>komfortu</p>
                    <h3>pracy</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="table">
              <div className="table-container">
                <div className="table-header">
                  <h2>pakiet dla nauczyciela</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ notebook </p>
                  <p>⇨ tablet graficzny</p>
                  <p>⇨ słuchawki</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>pakiet dla ucznia</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ router</p>
                  <p>⇨ drukarka</p>
                  <p>⇨ pendrive</p>
                  <p>⇨ akcesoria</p>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/eduOffer/edu3.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="officeStaff" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Innowacje i technologia</h4>
                  <h2>
                    Administracja szkoły, <br /> <b>gabinet dyrektora</b>
                  </h2>
                </div>
                <p className="headerTxt">
                  <b>Sięgaj po więcej razem z nami</b> - innowacyjne podejście
                  do zarządzania szkołą
                </p>
              </div>
              <div className="advantages-container-wrapper">
                <div className="advantages-container-wrapper-box">
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="ołówek"
                      src={
                        require("../assets/images/offer-adv/efektywnosc-edu.svg")
                          .default
                      }
                    />
                    <h3>zwiększenie</h3>
                    <p>efektywności</p>
                    <h3>pracy</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="żarówka"
                      src={
                        require("../assets/images/offer-adv/bezpieczenstwo-edu.svg")
                          .default
                      }
                    />

                    <h3>poprawa</h3>
                    <p>bezpieczeństwa</p>
                    <h3>danych</h3>
                  </div>
                  <div className="advantages-container-wrapper-element">
                    <img
                      className="advantages-img"
                      alt="samolot z papieru"
                      src={
                        require("../assets/images/offer-adv/innowacje.svg")
                          .default
                      }
                    />

                    <h3>wdrożenie</h3>
                    <p>innowacyjnych</p>
                    <h3>systemów</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="table list">
              <div className="table-container">
                <div className="table-header">
                  <h2>komputery, notebooki</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>
                    infrastruktura sieciowa <br /> i monitoring
                  </h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>Dzierżawa druku</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>UPS'y i agregaty prądotwórcze</h2>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header list-wrapper">
                  <ul>
                    <h2>
                      <b>drukarki i urządzenia biurowe</b>
                    </h2>

                    <li>⇨ drukarki 3D</li>
                    <li>⇨ filamenty</li>
                    <li>⇨ długopisy 3D</li>
                    <li>⇨ skanery 3D</li>
                  </ul>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header list-wrapper">
                  <ul>
                    <h2>
                      <b>matriały biurowe</b>
                    </h2>

                    <li>⇨ bindownice</li>
                    <li>⇨ laminatory</li>
                    <li>⇨ papier</li>
                    <li>⇨ telefony</li>
                    <li>⇨ fotele</li>
                    <li>⇨ akcesoria</li>
                  </ul>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/eduOffer/edu4.jpg"
              alt=""
            />
          </div>

          <div className="offer-element">
            <div id="services" className="anchor"></div>
            <div className="advantages-container offer">
              <div className="advantages txt">
                <div className="txt-container">
                  <h4>Przyszłość edukacji</h4>
                  <h2>
                    Sprzęt <br /> <b>multimedialny</b>
                  </h2>
                </div>
                <p className="headerTxt">
                  Inwestycja w nowoczesny sprzęt multimedialny, to patrzenie w
                  przyszłość
                </p>
              </div>
            </div>
            <div className="table">
              <div className="table-container">
                <div className="table-header">
                  <h2>monitory interaktywne</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ OPS'y i stojaki </p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>tabice interaktywne</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ projektory i głośniki</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>projektory dla edukacji</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ ekrany i uchwyty</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>monitory informacyjne</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ uchwyty</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>rozwiązania audio</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ kolumny</p>
                  <p>⇨ zestawy nagłośnieniowe</p>
                  <p>⇨ mikrofony</p>
                </div>
              </div>
              <div className="table-container">
                <div className="table-header">
                  <h2>rozwiązania video</h2>
                </div>
                <div className="table-container-wrapper">
                  <p>⇨ kamery</p>
                  <p>⇨ gimbale</p>
                  <p>⇨ foto</p>
                  <p>⇨ oświetlenie</p>
                </div>
              </div>
            </div>
            <StaticImage
              className="offer-img"
              src="../assets/images/eduOffer/edu5.jpg"
              alt=""
            />
          </div>
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>
          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => <Seo title="Oferta dla placówek edukacyjnych" />;
export default OfferBusinessPage;
